import { App, DirectiveBinding } from 'vue';

// Объявление расширенного интерфейса для HTMLElement с пользовательским свойством
interface HTMLElementWithClickOutsideEvent extends HTMLElement {
  clickOutsideEvent: (event: Event) => void;
}

const ClickOutsideDirective = {
  beforeMount(el: HTMLElementWithClickOutsideEvent, binding: DirectiveBinding) {
    // Обработчик события клика вне элемента
    el.clickOutsideEvent = function (event: Event) {
      // Проверка, был ли клик вне элемента или его дочерних элементов
      if (!(el === event.target || el.contains(event.target as Node))) {
        // Вызов функцию, переданную в директиву через параметр binding.value
        binding.value(event);
      }
    };
    // Добавка обработчик события клика к глобальному объекту document
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el: HTMLElementWithClickOutsideEvent) {
    // Удаление обработчик события клика из глобального объекта document
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
};

// Плагин для установки директивы как глобальной директивы в приложении Vue
export const clickOutside = {
  // Метод install, который будет вызван при установке плагина
  install(app: App) {
    // Регистриация директивы с именем 'click-outside' в качестве глобальной директивы
    app.directive('click-outside', ClickOutsideDirective);
  }
};
import { RouteRecordRaw } from 'vue-router';

export const configureRoutesMeta = (routes: RouteRecordRaw[], unauthorizedOnly: boolean | null, layout: string): RouteRecordRaw[] => {
  return routes.map((route) => ({
    ...route,
    meta: {
      ...route.meta,
      middleware: { unauthorizedOnly: unauthorizedOnly !== null ? unauthorizedOnly : false },
      layout: route.meta?.layout || layout,
    },
  }));
}
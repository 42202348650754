import { apiAeroClient } from "~/plugins/http";
import { BasesListPayload, IBaseListInfo } from "~/types/base";

export const getBases = async (params: BasesListPayload): Promise<IBaseListInfo> => {
  try {
    const { data } = await apiAeroClient.get('/Bases/List', { params });
    return data; 
  } catch (error) {
    throw error;
  }
};

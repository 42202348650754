import { computed, type ComputedRef, type Ref } from 'vue';
import { IButtonProps as IButtonPropsOriginal, ICommonEmits } from "@/types/components/buttons";

interface IButtonProps {
  ariaLabel?: Ref<IButtonPropsOriginal['ariaLabel']>;
}

interface IUseButtonReturn {
  computedAriaLabel: ComputedRef<string>; 
  handleClick: () => void;
  handleKeyDownEnter: () => void;
  handleKeyUpSpace: () => void;
}

export function useButton(props: IButtonProps, emits?: ICommonEmits) {
  const { ariaLabel } = props;

  const computedAriaLabel = computed((): string => ariaLabel?.value || '');

  const handleClick = (): void => {
    if (!emits) return;

    emits('click');
  };
  const handleKeyDownEnter = (): void => {
    if (!emits) return;

    emits('keydown.enter');
  };
  const handleKeyUpSpace = (): void => {
    if (!emits) return;

    emits('keyup.space');
  };

  return <IUseButtonReturn>{
    computedAriaLabel,
    handleClick,
    handleKeyDownEnter,
    handleKeyUpSpace
  }
}
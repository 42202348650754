export const getItemFromSessionStorage = <T>(key: string): T | null => {
  try {
    const item = sessionStorage.getItem(key);

    return item ? JSON.parse(item) as T : null;
  } catch (e) {
    console.error(`Не удалось получить ${key} из sessionStorage`, e);
    
    return null;
  }
};

export const setItemToSessionStorage = (key: string, data: unknown): void => {
  try {
    sessionStorage.setItem(key, JSON.stringify(data));
  } catch (e) {
    console.error(`Не удалось сохранить ${key} в sessionStorage`, e);
  }
};

export const removeItemFromSessionStorage = (key: string): void => {
  try {
    sessionStorage.removeItem(key);
  } catch (e) {
    console.error(`Не удалось удалить ${key} из sessionStorage`, e);
  }
};

export const getItemFromLocalStorage = <T>(key: string): T | null => {
  try {
    const item = localStorage.getItem(key);

    return item ? JSON.parse(item) as T : null;
  } catch (e) {
    console.error(`Не удалось получить ${key} из localStorage`, e);
    
    return null;
  }
};

export const setItemToLocalStorage = (key: string, data: unknown): void => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (e) {
    console.error(`Не удалось сохранить ${key} в localStorage`, e);
  }
};

export const removeItemFromLocalStorage = (key: string): void => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.error(`Не удалось удалить ${key} из localStorage`, e);
  }
};

<template>
  <button
    :type="nativeType"
    class="sm-button"
    :class="classList"
    :disabled="computedDisabled"
    role="button"
    :tab-index="tabIndex"
    :aria-label="computedAriaLabel"
    @click="handleClick"
    @keydown.enter="handleKeyDownEnter"
    @keyup.space="handleKeyUpSpace"
  >
    <sm-loader
      v-show="loading"
      class="sm-button__loading"
    ></sm-loader>

    <span class="sm-button__content">
      <slot name="default"></slot>

      <sm-icon
        v-if="showIcon"
        class="sm-button__icon"
        :name="`${icon}`"
      />
    </span>
  </button>
</template>

<script lang="ts" setup>
// Modules
import { computed, toRefs } from 'vue';

// Composables
import { useButton } from '@/composables/useButton';

// Components
import SmIcon from '@/components/common/SmIcon/SmIcon.vue';
import SmLoader from '@/components/common/loaders/SmLoader.vue';

// Types
import { Class } from '@/types/common';
import { IButtonProps, ICommonEmits } from '@/types/components/buttons';

// Props

const props = withDefaults(
  defineProps<IButtonProps>(),
  {
    type: 'primary',
    loading: false,
    icon: null,
    disabled: false,
    nativeType: 'button',
    tabIndex: 0,
    ariaLabel: '',
  }
);

const {
  type,
  loading,
  icon,
  disabled,
  nativeType,
  tabIndex,
  ariaLabel
} = toRefs(props);

// Emits

const emits = defineEmits<ICommonEmits>();

// Composables

const {
  computedAriaLabel,
  handleClick,
  handleKeyDownEnter,
  handleKeyUpSpace
} = useButton({
  ariaLabel
}, emits);

// Computed

const classList = computed((): Class => {
  let classList: Class = [];

  classList.push(`sm-button--${type.value}`);

  classList.push({
    'sm-button--loading': loading.value
  });

  return classList;
});

// const contentClasslist = computed((): Class => ({
//   sm-bu
// }))

const computedDisabled = computed((): boolean => loading.value || disabled.value);
const showIcon = computed((): boolean => type.value !== 'text' && !!icon.value);
</script>

<style lang="scss">
.sm-button {
  width: auto;

  padding: 7px 8px;

  border: none;
  border-radius: 100px;

  position: relative;

  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled:not(.sm-button--loading) {
    cursor: not-allowed;
  }
}

$types: (
  'primary': 'Primary',
  'secondary': 'Secondary',
);

@each $key, $type in $types {
  .sm-button--#{$key} {
    background-color: var(--#{$type});
    color: var(--On#{$type});

    @include transition(('background-color'));

    &:hover:not(.sm-button--loading) {
      background-color: var(--#{$type}Hover);
    }

    &:active {
      background-color: var(--#{$type}Press);
    }

    &:disabled:not(.sm-button--loading) {
      background-color: var(--#{$type}Disable);
      color: var(--On#{$type}Disable);
    }
  }
}

.sm-button--neutral {
  background-color: transparent;
  border: 1px solid var(--BorderMajor);
  color: var(--Body);

  @include transition(('border-color', 'color'));

  &:hover:not(.sm-button--loading) {
    border-color: var(--BorderHoverPress);
  }

  &:active {
    border-color: var(--BorderHoverPress);
    color: var(--Primary);
  }

  &:disabled:not(.sm-button--loading) {
    border-color: var(--BorderDisable);
    color: var(--Disabled);
  }
}

.sm-button--text {
  background-color: transparent;
  border-radius: 0;
  padding: 0;

  color: var(--Primary);

  &:not(.sm-button--loading) {
    border-bottom: 1px solid var(--Primary);
  }

  @include transition(('border-color', 'color'));

  &:hover:not(.sm-button--loading) {
    border-color: var(--PrimaryHover);
    color: var(--PrimaryHover);
  }

  &:active {
    border-color: var(--PrimaryPress);
    color: var(--PrimaryPress);
  }

  &:disabled:not(.sm-button--loading) {
    border-color: var(--PrimaryDisable);
    color: var(--PrimaryDisable);
  }
}

.sm-button--loading {
  min-width: 60px;

  cursor: wait;

  .sm-button__content {
    visibility: hidden;
  }
}

.sm-button__loading {
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 8px);
}

.sm-button__content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  @include mini;
}
</style>
import { ref } from 'vue';
import { defineStore } from '@pinia';
import { getBases as getBasesApi } from '~/api/bases';
// Types
import { BasesListPayload, IBaseListInfo } from '~/types/base';
// Utils
import {
  getItemFromLocalStorage,
  setItemToLocalStorage,
  removeItemFromLocalStorage,
  getItemFromSessionStorage,
  setItemToSessionStorage,
  removeItemFromSessionStorage
} from '@core/utils/persistenceStorage'
interface IBasesState {
    isLoading: boolean;
    data: any[];
    errors: null | string;
};

export const useBaseStore = defineStore('base', () => {
  // Reactive State
  const bases = ref<IBasesState>({
    isLoading: false,
    data: [],
    errors: null,
  });
  const baseId = ref<string>('')
  const baseName = ref<string>('');

  // Actions
  const isAdmin = () => getItemFromLocalStorage<boolean>('isAdmin') || false;

  const getItemFromStorage = (key: string) =>  isAdmin() ? getItemFromSessionStorage<string>(key) : getItemFromLocalStorage<string>(key);

  const setItemToStorage = (key: string, data: string) =>  isAdmin() ? setItemToSessionStorage(key, data) : setItemToLocalStorage(key, data);

  const removeItemFromStorage = (key: string) =>  isAdmin() ? removeItemFromSessionStorage(key) : removeItemFromLocalStorage(key);


  const getBases = async (params: BasesListPayload): Promise<IBaseListInfo> => {
    bases.value.isLoading = true;
    
    try {
      const response = await getBasesApi(params);

      bases.value.data = response.bases;

      return response;
    } catch (error) {
      throw(error);
    } finally {
      bases.value.isLoading = false;
    }
  };

  const getBaseInfo = () => {
    const base = getItemFromStorage('base') || '';
    // const base = getItemFromStorage('selectedBase') || '';
    const name = getItemFromStorage('selectedBaseName') || '';

    if (base && name) setBaseInfo(base, name);
  };

  const setBaseInfo = (base: string, name: string) => {
    setItemToStorage('base', base);
    setItemToStorage('selectedBaseName', name);

    baseId.value = base;
    baseName.value = name;
  }

  const clearBaseInfo = () => {
    removeItemFromStorage('base');
    removeItemFromStorage('selectedBaseName');

    baseId.value = '';
    baseName.value = '';
  }

  return {
    baseId,
    baseName,
    getBases,
    getBaseInfo,
    setBaseInfo,
    clearBaseInfo,
  }
});


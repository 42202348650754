import { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/debtor-relations',
    name: 'DebtorRelations',
    component: () => import('~/views/debtors/DebtorRelations.vue'),
    meta: { title: 'Работа с должниками' }
  },
  // Reports
  {
    path: '/debtor-relations/debtors-by-periods-report',
    name: 'DebtorsByPeriodsReport',
    component: () => import('~/views/debtors/reports/DebtorsByPeriodsReport.vue'),
    meta: { title: 'Должники по периодам' }
  },
  {
    path: '/debtor-relations/claimants-report',
    name: 'ClaimantsReport',
    component: () => import('~/views/debtors/reports/ClaimantsReport.vue'),
    meta: { title: 'Отчет по взыскателям (оплатам) за период' }
  },
  {
    path: '/debtor-relations/debtor-cases-statuses-report',
    name: 'DebtorsCasesStatusesReport',
    component: () => import('~/views/debtors/reports/DebtorsCasesStatusesReport.vue'),
    meta: { title: 'Отчет по статусам работы с должниками' }
  },
  {
    path: '/debtor-relations/debtor-idents-statement-report',
    name: 'DebtorsIdentsStatementReport',
    component: () => import('~/views/debtors/reports/DebtorsIdentsStatementReport.vue'),
    meta: { title: 'База лицевых счетов (ведомость по должникам)' }
  },
  {
    path: '/debtor-relations/debtors-payment-report',
    name: 'DebtorsPaymentsReport',
    component: () => import('~/views/debtors/reports/DebtorsPaymentsReport.vue'),
    meta: { title: 'Оплата по должникам' }
  },
  {
    path: '/debtor-relations/debtors-informing-effectiveness-report',
    name: 'DebtorsInformingEffectivenessReport',
    component: () => import('~/views/debtors/reports/DebtorsInformingEffectivenessReport.vue'),
    meta: { title: 'Отчет по эффективности автообзвонов' }
  },
];

import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from '@vue-router';
import { unauthorizedRoutes } from './unauthorizedRoutes';
import { authorizedRoutes } from './authorizedRoutes';
import { publicRoutes } from './publicRoutes/';
// middlewate
// Подумать над именованием
import { loadLayout } from './middleware/loadLayout';
import { setPageTitle } from './middleware/setPageTitle';
import { handleAlwaysAvailableRoute } from './middleware/alwaysAvailableRoute';
import { checkUnauthorizedRoute, handleUnauthorizedRoute } from './middleware/unauthorizedRoute';
// import { checkAuthorizedRoute } from './middleware/authorizedRoute';
// import { setRedirectAfterLoginRoute, handleRedirectAfterLogin } from './middleware/redirectAfterLoginRoute';
import { handleBaseSelection } from './middleware/baseRoute';
import { getItemFromLocalStorage, getItemFromSessionStorage } from '@core/utils/persistenceStorage';

const routes: Array<RouteRecordRaw> = [...unauthorizedRoutes, ...authorizedRoutes, ...publicRoutes];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});
router.beforeEach(async (to, from, next) => {
  await loadLayout(to);
  setPageTitle();

  const isAdmin = getItemFromLocalStorage<boolean>('isAdmin');

  const token: string | null = getItemFromLocalStorage('accessToken');
  const base: string | null = isAdmin ? getItemFromSessionStorage('base') : getItemFromLocalStorage('base');

  if (handleAlwaysAvailableRoute(to, next)) return;

  const conditionRedirectToLoginPage = !checkUnauthorizedRoute(to) && !token;

  if (conditionRedirectToLoginPage) {
    // setRedirectAfterLoginRoute(to);
    return next({ name: 'Login' });
  }

  if (handleUnauthorizedRoute(to, token, base, next)) return;
  if (handleBaseSelection(to, token, base, next)) return;

   // handleRedirectAfterLogin(to, base);

  next();
})
export default router;

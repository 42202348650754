// TODO: Добавить типизацию
// TODO: Посмотреть другую реализацию для данного функционала
// TODO: Доработать функционал, так как он забагованый

// Функция проверки, является ли текущий маршрут валидным для базы
export const checkValidRouteBase = (to: any): string | null => {
  const INVALID_ROUTE_BASE_PARAMS = ['select-base', 'login', 'registration', 'reset-password'];
  return INVALID_ROUTE_BASE_PARAMS.includes(to.params?.currentBase) ? null : (to.params?.currentBase as string | null);
};

// Функция для обработки маршрутов и перенаправления на выбор базы
export const handleBaseSelection = (to: any, token: string | null, base: string | null, next: Function): boolean => {
  const routeBase = checkValidRouteBase(to);

  const shouldRedirectToSelectBase = to.name !== 'SelectBase' && token && !base && !routeBase;

  if (shouldRedirectToSelectBase) {
    next({ name: 'SelectBase' });
    return true;
  }
  
  return false;
};
import dayjs from "dayjs"
import localizedFormat from 'dayjs/plugin/localizedFormat.js'
import customParseFormat from 'dayjs/plugin/customParseFormat.js'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import duration from 'dayjs/plugin/duration' 
import weekday from 'dayjs/plugin/weekday' 
import ru from 'dayjs/locale/ru';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

dayjs.extend(customParseFormat)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(duration);
dayjs.extend(weekday);
dayjs.extend(quarterOfYear);

// const language = localStorage.getItem('language')
// const locale = language ? language : 'ru'

// if (locale === 'ru') {
dayjs.locale({
  ...ru, 
  weekStart: 1
});
// }
  
dayjs.extend(localizedFormat);

export default {
  install: (app: any) => {
    const globalDayjs = dayjs;

    // This is what you want:
    app.config.globalProperties.$dayjs = globalDayjs;

    // But this is the recommended approach:
    app.provide('dayjs', globalDayjs);

    // feel free to use both at once though
  }
}

import { RouteRecordRaw } from "vue-router";

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/electronic-control-room',
    name: 'ElectronicControlRoom',
    component: () => import('~/views/electronicControlRoom/ElectronicContromRoom.vue'),
    meta: { title: 'Электронная диспетчерская' }
  },
  
  /* -------------------------------------------------------------------------*/
  /* Reports                                                                  */
  /* -------------------------------------------------------------------------*/

  {
    path: '/electronic-control-room/ads-analitical-report',
    name: 'AdsAnaliticalReport',
    component: () => import('~/views/electronicControlRoom/reports/AdsAnaliticalReport.vue'),
    meta: { title: 'Анализ поступивших заявок' }
  },
  {
    path: '/electronic-control-room/specific-services-report',
    name: 'SpecifiedServicesReport',
    component: () => import('~/views/electronicControlRoom/reports/SpecifiedServicesReport.vue'),
    meta: { title: 'Эксперт отчет по услугам' }
  },
  {
    path: '/electronic-control-room/request-division-report',
    name: 'RequestDivisionReport',
    component: () => import('~/views/electronicControlRoom/reports/RequestDivisionReport.vue'),
    meta: { title: 'Эксперт отчет по заявкам, подразделениям' }
  },
  {
    path: '/electronic-control-room/request-date-report',
    name: 'RequestDateReport',
    component: () => import('~/views/electronicControlRoom/reports/RequestDateReport.vue'),
    meta: { title: 'Эксперт отчет заявки по датам' }
  },
  {
    path: '/electronic-control-room/phonograms-report',
    name: 'PhonogramsReport',
    component: () => import('~/views/electronicControlRoom/reports/PhonogramsReport.vue'),
    meta: { title: 'Реестр телефонограмм' }
  },
  {
    path: '/electronic-control-room/materials-consumption-report',
    name: 'MaterialsConsumptionReport',
    component: () => import('~/views/electronicControlRoom/reports/MaterialsConsumptionReport.vue'),
    meta: { title: 'Отчет по расходованию материалов' }
  },
  {
    path: '/electronic-control-room/idents-with-electronic-bills-report',
    name: 'IdentsWithElectronicBillsReport',
    component: () => import('~/views/electronicControlRoom/reports/IdentsWithElectronicBillsReport.vue'),
    meta: { title: 'Лицевые счета, получающие квитанции в эл. виде' }
  },
  {
    path: '/electronic-control-room/idents-with-electronic-bills-changes-report',
    name: 'IdentsWithElectronicBillsChangesReport',
    component: () => import('~/views/electronicControlRoom/reports/IdentsWithElectronicBillsChangesReport.vue'),
    meta: { title: 'Лицевые счета, изменившие галку согласия получать квитанции в электронном виде' }
  },
  {
    path: '/electronic-control-room/deadline-report',
    name: 'DeadlineReport',
    component: () => import('~/views/electronicControlRoom/reports/DeadlineReport.vue'),
    meta: { title: 'Отчет по дедлайну' }
  },
  {
    path: '/electronic-control-room/calls-report',
    name: 'CallsReport',
    component: () => import('~/views/electronicControlRoom/reports/CallsReport.vue'),
    meta: { title: 'Эксперт отчет по звонкам' }
  },
  {
    path: '/electronic-control-room/ads-yammers-requests-report',
    name: 'AdsYammerRequestsReport',
    component: () => import('~/views/electronicControlRoom/reports/AdsYammerRequestsReport.vue'),
    meta: { title: 'Жалобы и обращения' }
  },
  {
    path: '/electronic-control-room/tasks-report',
    name: 'TasksReport',
    component: () => import('~/views/electronicControlRoom/reports/TasksReport.vue'),
    meta: { title: 'Отчет по задачам' }
  },
  {
    path: '/electronic-control-room/ads-requests-phones-report',
    name: 'AdsRequestsPhonesReport',
    component: () => import('~/views/electronicControlRoom/reports/AdsRequestsPhonesReport.vue'),
    meta: { title: 'Телефоны из заявок' }
  },
  {
    path: '/electronic-control-room/ads-requests-performing-report',
    name: 'AdsRequestsPerformingReport',
    component: () => import('~/views/electronicControlRoom/reports/AdsRequestsPerformingReport.vue'),
    meta: { title: 'Заявки по исполнителям' }
  },
  {
    path: '/electronic-control-room/ads-pass-journal-report',
    name: 'AdsPassJournalReport',
    component: () => import('~/views/electronicControlRoom/reports/AdsPassJournalReport.vue'),
    meta: { title: 'Журнал оформленных заявок на пропуск' }
  },
  {
    path: '/electronic-control-room/ads-my-performed-journal-report',
    name: 'AdsMyPerformedJournalReport',
    component: () => import('~/views/electronicControlRoom/reports/AdsMyPerformedJournalReport.vue'),
    meta: { title: 'Мои выполненные заявки за период' }
  },
  {
    path: '/electronic-control-room/ads-journal-report',
    name: 'AdsJournalReport',
    component: () => import('~/views/electronicControlRoom/reports/AdsJournalReport.vue'),
    meta: { title: 'Журнал заявок' }
  },
  {
    path: '/electronic-control-room/ads-journal-capital-invest-report',
    name: 'AdsJournalCapitalInvestReport',
    component: () => import('~/views/electronicControlRoom/reports/AdsJournalCapitalInvestReport.vue'),
    meta: { title: 'Журнал заявок (вариант 2)' }
  },
  {
    path: '/electronic-control-room/ads-call-statistics-report',
    name: 'AdsCallsStatisticsReport',
    component: () => import('~/views/electronicControlRoom/reports/AdsCallsStatisticsReport.vue'),
    meta: { title: 'Статистика звонков' }
  },
  {
    path: '/electronic-control-room/ads-mark-overview-report',
    name: 'AdsMarksOverviewReport',
    component: () => import('~/views/electronicControlRoom/reports/AdsMarksOverviewReport.vue'),
    meta: { title: 'Оценка удовлетворенности' }
  },
  {
    path: '/electronic-control-room/ads-incoming-requests-report',
    name: 'AdsIncomingRequestsReport',
    component: () => import('~/views/electronicControlRoom/reports/AdsIncomingRequestsReport.vue'),
    meta: { title: 'Отчет по поступившим обращениям в диспетчерскую службу' }
  },
  {
    path: '/electronic-control-room/ads-missed-call-report',
    name: 'AdsMissedCallReport',
    component: () => import('~/views/electronicControlRoom/reports/AdsMissedCallReport.vue'),
    meta: { title: 'Отчет по пропущенным звонкам' }
  },
  {
    path: '/electronic-control-room/last-users-activity-report',
    name: 'LastUsersActivityReport',
    component: () => import('~/views/electronicControlRoom/reports/LastUsersActivityReport.vue'),
    meta: { title: 'Отчет по последней активности пользователей сотрудников' }
  },
  // {
  //   path: '/electronic-control-room/ads-employee-geolocation-report',
  //   name: 'AdsEmployeeGeolocation',
  //   component: () => import('~/views/electronicControlRoom/reports/AdsEmployeeGeolocation.vue'),
  //   meta: { title: 'Отчет по геолокации сотрудников' }
  // },

  /* -------------------------------------------------------------------------*/
  /* Lists                                                                    */
  /* -------------------------------------------------------------------------*/

  // Addresses
  {
    path: '/electronic-control-room/houses',
    name: 'ElectronicControlRoomHouses',
    component: () => import('~/views/electronicControlRoom/lists/TestList.vue'),
    meta: { title: 'Лицевые счета' }
  },
]